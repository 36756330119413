import { TabContext, TabList } from '@mui/lab';
import { Tab } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { RoutingPattern } from '../../Routes';
import { AutoGraphIcon, FavoriteIcon, HistoryIcon } from '../Icons';

export type TabMode = 'trend' | 'recent' | 'liked';

export const TabNav = (props: { mode: TabMode }) => {
    const navigate = useNavigate();
    return (
        <TabContext value={props.mode}>
            <TabList
                variant="fullWidth"
                onChange={(_, value) => {
                    const next = value === 'recent' ? '' : `/${value}`;
                    navigate(`${RoutingPattern.notes}${next}`);
                }}
            >
                <Tab value="recent" label="新着記事" icon={<HistoryIcon />} iconPosition="start" />
                <Tab value="trend" label="トレンド" icon={<AutoGraphIcon />} iconPosition="start" />
                <Tab
                    value="liked"
                    label="いいね！した記事"
                    icon={<FavoriteIcon />}
                    iconPosition="start"
                />
            </TabList>
        </TabContext>
    );
};
