import {
    Avatar,
    Box,
    Card,
    CardActionArea,
    CardContent,
    cardContentClasses,
    Typography,
    type CardProps,
} from '@mui/material';
import type { NotesArticle } from '@spec/Notes';
import dayjs from 'dayjs';
import { fullName } from '../../domains/Talent';
import { useNotesArticle } from '../../queries/notes';
import { useTalentsContext, useTenantContext } from '../Context';
import { FlexBox } from '../FlexBox';
import { AccessTimeIcon, FeedIcon } from '../Icons';
import { IconText } from '../IconText';
import { ExternalLink, RouterLink } from '../RouterLink';
import { TalentAvatar } from '../TalentAvatar';
import { WaitQuery } from '../WaitLoading';
import { useAuthor } from './Hooks';
import { getArticleUrl, getAuthorUrl, getTagUrl } from './urls';

export const NoteOgp = (props: { url: string }) => {
    const { tenant } = useTenantContext();
    const articleMatch = props.url.match(
        new RegExp(`^${window.location.origin}/${tenant}/notes/[^/]+/([0-9]+)$`)
    );
    if (articleMatch !== null) {
        const articleId = Number(articleMatch[1]);
        return <ArticleOgpCard articleId={articleId} />;
    }

    const tagMatch = props.url.match(
        new RegExp(`^${window.location.origin}/${tenant}/notes/tag/([^/]+)$`)
    );
    if (tagMatch !== null) {
        const tagName = tagMatch[1];
        return <TagOgpCard tagName={tagName} />;
    }

    const authorMatch = props.url.match(
        new RegExp(`^${window.location.origin}/${tenant}/notes/@([^/]+)$`)
    );
    if (authorMatch !== null) {
        const authorName = authorMatch[1];
        return <AuthorOgpCard authorName={authorName} />;
    }

    return (
        <ExternalLink underline="always" href={props.url}>
            {props.url}
        </ExternalLink>
    );
};

const OgpCard = (props: Omit<CardProps, 'sx'>) => (
    <Card
        {...props}
        sx={(theme) => ({
            maxWidth: { xs: '100%', sm: '600px' },
            my: 1,
            p: 0,
            backgroundColor: theme.palette.background.default,
            [`& .${cardContentClasses.root}`]: {
                p: 0,
                pl: 2,
                display: 'flex',
                alignItems: 'center',
                gap: 1,
            },
        })}
    />
);

const ArticleOgpCard = (props: { articleId: number }) => {
    const maybeArticle = useNotesArticle(props.articleId);
    return (
        <OgpCard>
            <WaitQuery query={maybeArticle}>
                {({ data }) => <ArticleOgpContent article={data.article} />}
            </WaitQuery>
        </OgpCard>
    );
};

const ArticleOgpContent = (props: { article: NotesArticle }) => {
    const author = useAuthor(props.article);
    const articleUrl = getArticleUrl(props.article.id, author.hitonowaId);
    return (
        <CardActionArea component={RouterLink} to={articleUrl}>
            <CardContent>
                <Box flexGrow={1}>
                    <Typography variant="h4" mt={0.5} mb={0}>
                        {props.article.title}
                    </Typography>
                    <FlexBox gap={1}>
                        <Typography variant="body2">
                            {fullName(author)}
                            {author.nicknames !== '' && `（${author.nicknames}）`}
                        </Typography>
                        <IconText icon={AccessTimeIcon}>
                            {dayjs(props.article.publishedAt).format('YYYY-MM-DD HH:mm')}
                        </IconText>
                    </FlexBox>
                </Box>
                <TalentAvatar size="mediumLarge" talent={author} />
            </CardContent>
        </CardActionArea>
    );
};

const TagOgpCard = (props: { tagName: string }) => {
    const tagUrl = getTagUrl(props.tagName);
    return (
        <OgpCard>
            <CardActionArea component={RouterLink} to={tagUrl}>
                <CardContent>
                    <Box flexGrow={1}>
                        <Typography variant="h4" mt={0.5} mb={0}>
                            #{props.tagName}の記事一覧
                        </Typography>
                    </Box>
                    <Avatar variant="rounded" sx={{ width: 80, height: 80 }}>
                        <FeedIcon fontSize="large" />
                    </Avatar>
                </CardContent>
            </CardActionArea>
        </OgpCard>
    );
};

const AuthorOgpCard = (props: { authorName: string }) => {
    const { talents } = useTalentsContext();
    const author = talents.find((v) => v.hitonowaId === props.authorName);
    if (author === undefined) {
        return (
            <OgpCard>
                <CardContent>
                    <Box flexGrow={1}>
                        <Typography variant="h4" mt={0.5}>
                            {props.authorName}さんが見つかりません
                        </Typography>
                        <Typography variant="body2" mb={0}>
                            ヒトノワIDが変更されている可能性があります
                        </Typography>
                    </Box>
                    <Avatar variant="rounded" sx={{ width: 80, height: 80 }}>
                        <FeedIcon fontSize="large" />
                    </Avatar>
                </CardContent>
            </OgpCard>
        );
    }
    const authorUrl = getAuthorUrl(author.hitonowaId);
    return (
        <OgpCard>
            <CardActionArea component={RouterLink} to={authorUrl}>
                <CardContent>
                    <Box flexGrow={1}>
                        <Typography variant="h4" mt={0.5} mb={0}>
                            {props.authorName}さんの記事一覧
                        </Typography>
                    </Box>
                    <TalentAvatar size="mediumLarge" talent={author} />
                </CardContent>
            </CardActionArea>
        </OgpCard>
    );
};
