import { LoadingButton } from '@mui/lab';
import { Box, Card, CardContent, Container, Typography } from '@mui/material';
import { NotesArticleSummary } from '@spec/Notes';
import dayjs from 'dayjs';
import { useLocation } from 'react-router-dom';
import { fullName } from '../../../domains/Talent';
import { useSearchArticles } from '../../../queries/notes';
import { ActionContainer } from '../../ActionButtons';
import { FlexBox } from '../../FlexBox';
import { IconText } from '../../IconText';
import { AccessTimeIcon, FeedIcon } from '../../Icons';
import { SubTitle } from '../../PageTitle';
import { ExternalLink, RouterLink } from '../../RouterLink';
import { TalentAvatar } from '../../TalentAvatar';
import { WaitInfiniteQuery } from '../../WaitLoading';
import { HitonowaId } from '../../talent/HitonowaId';
import { ArticleTags } from '../ArticleTags';
import { KIBELA_ARCHIVE_URL } from '../Contract';
import { useAuthor } from '../Hooks';
import { LikesCounter } from '../LikesCounter';
import { getArticleUrl, getAuthorUrl } from '../urls';
import { SearchForm } from './SearchForm';

export const Search: React.FC = () => {
    const location = useLocation();
    const search = new URLSearchParams(location.search);
    const word = search.get('word') ?? '';
    const maybeArticles = useSearchArticles(word);
    return (
        <Box>
            <Container maxWidth="md">
                <SearchForm word={word} />
                <Box my={2}>
                    <SubTitle title={`${word} の検索結果`} />
                </Box>
                <Box my={2}>
                    <ExternalLink icon href={[KIBELA_ARCHIVE_URL, word].join('')}>
                        Kibelaの記事を探す
                    </ExternalLink>
                </Box>
                <WaitInfiniteQuery query={maybeArticles}>
                    {({ data, isFetching, fetchNextPage, hasNextPage }) => (
                        <Box>
                            <ArticleList articles={data.pages.flatMap((page) => page.articles)} />
                            <ActionContainer>
                                <LoadingButton
                                    fullWidth
                                    variant="outlined"
                                    size="medium"
                                    loading={isFetching}
                                    loadingPosition="start"
                                    startIcon={<FeedIcon />}
                                    onClick={() => fetchNextPage()}
                                    disabled={!hasNextPage}
                                >
                                    もっと見る
                                </LoadingButton>
                            </ActionContainer>
                        </Box>
                    )}
                </WaitInfiniteQuery>
            </Container>
        </Box>
    );
};

const ArticleList: React.FC<{ articles: NotesArticleSummary[] }> = (props) => {
    if (props.articles.length === 0) {
        return (
            <Box mt={10} textAlign="center">
                <Typography color="error">記事が見つかりません。</Typography>
            </Box>
        );
    }
    return (
        <Box>
            {props.articles.map((v) => (
                <ArticleCard key={v.id} article={v} />
            ))}
        </Box>
    );
};

const ArticleCard: React.FC<{ article: NotesArticleSummary }> = (props) => {
    const v = props.article;
    const author = useAuthor(v);
    const articleUrl = getArticleUrl(v.id, author.hitonowaId);
    const authorUrl = getAuthorUrl(author.hitonowaId);
    return (
        <Card square={false}>
            <CardContent sx={{ pb: 0 }}>
                <FlexBox flexWrap="nowrap" gap={1}>
                    <RouterLink to={articleUrl}>
                        <TalentAvatar size="medium" talent={author} />
                    </RouterLink>
                    <Box flexGrow={1}>
                        <RouterLink mb={1} variant="h6" to={articleUrl}>
                            {v.title}
                        </RouterLink>
                        <FlexBox gap={1} my={-0.5}>
                            <Typography variant="body2">
                                {fullName(author)}
                                {author.nicknames !== '' && `（${author.nicknames}）`}
                            </Typography>
                            <RouterLink variant="body2" to={authorUrl}>
                                <HitonowaId hitonowaId={author.hitonowaId} />
                            </RouterLink>
                            <IconText icon={AccessTimeIcon}>
                                {dayjs(props.article.publishedAt).format('YYYY-MM-DD HH:mm')}
                            </IconText>
                            <ArticleTags tags={v.tags} />
                        </FlexBox>
                    </Box>
                    <LikesCounter article={props.article} />
                </FlexBox>
            </CardContent>
        </Card>
    );
};
