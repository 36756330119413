import {
    Box,
    Table,
    TableBody,
    TableCell,
    tableCellClasses,
    TableRow,
    Typography,
} from '@mui/material';
import type { Talent, TalentTag } from '@spec/Talent';
import { sortByKey } from '../../../../lib/ArrayUtils';

export const TalentTags = (props: { talent: Talent }) => {
    if (props.talent.tags.length === 0) {
        return null;
    }
    const categories = new Map<string, TalentTag[]>();
    for (const tag of sortByKey(props.talent.tags, 'category')) {
        const key = tag.category;
        const x = categories.get(key) ?? [];
        x.push(tag);
        categories.set(key, x);
    }
    return (
        <Box display="flex" gap={4}>
            {[...categories.entries()].map(([category, tags]) => (
                <Box
                    key={category}
                    sx={(theme) => ({
                        pl: 1,
                        borderLeft: `4px solid ${theme.palette.primary.light}`,
                    })}
                >
                    <Typography color="primary" variant="body2" sx={{ fontWeight: 700 }}>
                        {category}
                    </Typography>
                    <Table
                        size="small"
                        sx={{
                            width: 'auto',
                            mt: 0.5,
                            [`& .${tableCellClasses.root}`]: {
                                px: 0.5,
                                py: '2px',
                                border: 'None',
                            },
                        }}
                    >
                        <TableBody>
                            {tags.map((v) => (
                                <TableRow key={v.id}>
                                    <TableCell sx={{ border: 'none' }}>{v.term}</TableCell>
                                    <TableCell sx={{ border: 'none' }}>{v.label}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </Box>
            ))}
        </Box>
    );
};
