import { TeamId } from '@spec/Organization';
import { ROOT_TEAM_ID } from '../../TeamDrill';

export interface ListFilterCondition {
    joinedYears: number[];
    hiringCategory: HiringCategory;
    grades: string[];
    employmentStatus: string[];
    secondmentType: string[];
    employeeCode: string;
    workplace: string | null;
    teamId: TeamId | null;
    enrollment: Enrollment;
    name: string;
    showSuspended: boolean;
    noProfileImage: boolean;
    noSlackIntegration: boolean;
    signIn: SignInType;
}

export interface ListViewCondition {
    sortOrder: SortOrder;
    sortReverse: boolean;
    itemsPerPage: number;
    displayMode: DisplayMode;
}

const SortOrders = ['joinedAt', 'employeeCode', 'grade', 'leftAt'] as const;
export type SortOrder = (typeof SortOrders)[number];

export const isSortOrder = (v: unknown): v is SortOrder =>
    typeof v === 'string' && new Set<string>(SortOrders).has(v);

const DEFAULT_SORT_ORDER = 'joinedAt';

const DEFAULT_ITEMS_PER_PAGE = 20;

const DisplayMode = ['list', 'gallery'] as const;
export type DisplayMode = (typeof DisplayMode)[number];
export const isDisplayMode = (v: unknown): v is DisplayMode =>
    typeof v === 'string' && new Set<string>(DisplayMode).has(v);
const DEFAULT_DISPLAY_MODE: DisplayMode = 'list';

const HiringCategories = ['all', 'newGraduate', 'experienced'] as const;
export type HiringCategory = (typeof HiringCategories)[number];
const DEFAULT_HIRING_CATEGORY: HiringCategory = 'all';

export const isHiringCategory = (v: unknown): v is HiringCategory =>
    typeof v === 'string' && new Set<string>(HiringCategories).has(v);

const Enrollments = ['all', 'leaved', 'current'] as const;
export type Enrollment = (typeof Enrollments)[number];

const DEFAULT_ENROLLMENT: Enrollment = 'current';

export const isEnrollment = (v: unknown): v is Enrollment =>
    typeof v === 'string' && new Set<string>(Enrollments).has(v);

const SignInTypes = ['all', 'allowed', 'restricted'] as const;
export type SignInType = (typeof SignInTypes)[number];
const DEFAULT_SIGN_IN_TYPE: SignInType = 'all';
export const isSignInType = (v: unknown): v is SignInType =>
    typeof v === 'string' && new Set<string>(SignInTypes).has(v);

const QUERY_DELIMITER = '-';
const filterKeys: Record<
    | keyof ListFilterCondition
    | keyof ListViewCondition
    | 'onlyNewGraduate' // to keep backward compatibility
    | 'page',
    string
> = {
    // ListFilterCondition
    joinedYears: 'y',
    onlyNewGraduate: 'ng',
    hiringCategory: 'hc',
    grades: 'g',
    employmentStatus: 'e',
    secondmentType: 'secondment',
    employeeCode: 'ec',
    workplace: 'w',
    name: 'n',
    teamId: 't',
    enrollment: 'en',
    showSuspended: 'sus',
    noProfileImage: 'img',
    noSlackIntegration: 'slack',
    signIn: 'signin',
    // ListViewCondition
    sortOrder: 'sort',
    sortReverse: 'rev',
    itemsPerPage: 'items',
    displayMode: 'mode',
    // current page
    page: 'p',
};

export const parsePage = (query: string): number => {
    const searchParams = new URLSearchParams(query);
    return Number(searchParams.get(filterKeys.page) ?? 1);
};

export const defaultListFilterCondition: ListFilterCondition = {
    joinedYears: [],
    hiringCategory: DEFAULT_HIRING_CATEGORY,
    grades: [],
    employmentStatus: [],
    secondmentType: [],
    employeeCode: '',
    workplace: null,
    teamId: ROOT_TEAM_ID,
    enrollment: DEFAULT_ENROLLMENT,
    name: '',
    showSuspended: false,
    noProfileImage: false,
    noSlackIntegration: false,
    signIn: DEFAULT_SIGN_IN_TYPE,
};

export const initializeFilterCondition = (query: string): ListFilterCondition => {
    const searchParams = new URLSearchParams(query);
    return {
        joinedYears: (() => {
            const joinedYears = searchParams.get(filterKeys.joinedYears);
            if (joinedYears === null) {
                return [];
            }
            return joinedYears
                .split(QUERY_DELIMITER)
                .map(Number)
                .filter((v) => Number.isInteger(v));
        })(),
        hiringCategory: (() => {
            if (searchParams.get(filterKeys.onlyNewGraduate)) {
                // to keep backward compatibility
                return 'newGraduate';
            }
            const hiringCategory = searchParams.get(filterKeys.hiringCategory);
            return isHiringCategory(hiringCategory) ? hiringCategory : DEFAULT_HIRING_CATEGORY;
        })(),
        grades: (() => {
            const grades = searchParams.get(filterKeys.grades);
            return grades ? grades.split(QUERY_DELIMITER) : [];
        })(),
        employmentStatus: (() => {
            const employmentStatus = searchParams.get(filterKeys.employmentStatus);
            return employmentStatus ? employmentStatus.split(QUERY_DELIMITER) : [];
        })(),
        secondmentType: (() => {
            const secondmentType = searchParams.get(filterKeys.secondmentType);
            return secondmentType ? secondmentType.split(QUERY_DELIMITER) : [];
        })(),
        employeeCode: (() => {
            const employeeCode = searchParams.get(filterKeys.employeeCode);
            if (employeeCode) {
                return employeeCode.slice(0, 2);
            }
            return '';
        })(),
        workplace: (() => {
            return searchParams.get(filterKeys.workplace);
        })(),
        teamId: (() => {
            const teamId = searchParams.get(filterKeys.teamId);
            if (teamId && Number.isInteger(Number(teamId))) {
                return Number(teamId);
            }
            return null;
        })(),
        enrollment: (() => {
            const enrollment = searchParams.get(filterKeys.enrollment);
            return isEnrollment(enrollment) ? enrollment : DEFAULT_ENROLLMENT;
        })(),
        name: (() => {
            return searchParams.get(filterKeys.name) ?? '';
        })(),
        showSuspended: (() => {
            return !!searchParams.get(filterKeys.showSuspended);
        })(),
        noProfileImage: (() => {
            return !!searchParams.get(filterKeys.noProfileImage);
        })(),
        noSlackIntegration: (() => {
            return !!searchParams.get(filterKeys.noSlackIntegration);
        })(),
        signIn: (() => {
            const signIn = searchParams.get(filterKeys.signIn);
            return isSignInType(signIn) ? signIn : DEFAULT_SIGN_IN_TYPE;
        })(),
    };
};

export const initializeViewCondition = (query: string): ListViewCondition => {
    const searchParams = new URLSearchParams(query);
    return {
        sortOrder: (() => {
            const order = searchParams.get(filterKeys.sortOrder);
            return isSortOrder(order) ? order : DEFAULT_SORT_ORDER;
        })(),
        sortReverse: (() => {
            return searchParams.get(filterKeys.sortReverse) !== '0';
        })(),
        itemsPerPage: (() => {
            const value = Number(searchParams.get(filterKeys.itemsPerPage));
            return value > 1 ? value : DEFAULT_ITEMS_PER_PAGE;
        })(),
        displayMode: (() => {
            const mode = searchParams.get(filterKeys.displayMode);
            return isDisplayMode(mode) ? mode : DEFAULT_DISPLAY_MODE;
        })(),
    };
};

export const serializeListCondition = (
    filter: ListFilterCondition,
    view: ListViewCondition,
    page: number
): URLSearchParams => {
    const params = new URLSearchParams();

    // list filter condition
    if (filter.joinedYears.length !== 0) {
        params.set(filterKeys.joinedYears, filter.joinedYears.join(QUERY_DELIMITER));
    }
    if (filter.hiringCategory !== DEFAULT_HIRING_CATEGORY) {
        params.set(filterKeys.hiringCategory, filter.hiringCategory);
    }
    if (filter.grades.length !== 0) {
        params.set(filterKeys.grades, filter.grades.join(QUERY_DELIMITER));
    }
    if (filter.employmentStatus.length !== 0) {
        params.set(filterKeys.employmentStatus, filter.employmentStatus.join(QUERY_DELIMITER));
    }
    if (filter.secondmentType.length !== 0) {
        params.set(filterKeys.secondmentType, filter.secondmentType.join(QUERY_DELIMITER));
    }
    if (filter.employeeCode !== '') {
        params.set(filterKeys.employeeCode, filter.employeeCode);
    }
    if (filter.workplace !== null) {
        params.set(filterKeys.workplace, filter.workplace);
    }
    if (filter.name !== '') {
        params.set(filterKeys.name, filter.name);
    }
    if (filter.teamId !== null && filter.teamId !== ROOT_TEAM_ID) {
        params.set(filterKeys.teamId, `${filter.teamId}`);
    }
    if (filter.enrollment !== DEFAULT_ENROLLMENT) {
        params.set(filterKeys.enrollment, filter.enrollment);
    }
    if (filter.showSuspended === true) {
        params.set(filterKeys.showSuspended, '1');
    }
    if (filter.noProfileImage === true) {
        params.set(filterKeys.noProfileImage, '1');
    }
    if (filter.noSlackIntegration === true) {
        params.set(filterKeys.noSlackIntegration, '1');
    }
    if (filter.signIn !== DEFAULT_SIGN_IN_TYPE) {
        params.set(filterKeys.signIn, filter.signIn);
    }

    // list view condition
    if (view.sortOrder !== DEFAULT_SORT_ORDER) {
        params.set(filterKeys.sortOrder, view.sortOrder);
    }
    if (view.sortReverse === false) {
        params.set(filterKeys.sortReverse, '0');
    }
    if (view.itemsPerPage !== DEFAULT_ITEMS_PER_PAGE) {
        params.set(filterKeys.itemsPerPage, `${view.itemsPerPage}`);
    }
    if (view.displayMode !== DEFAULT_DISPLAY_MODE) {
        params.set(filterKeys.displayMode, view.displayMode);
    }

    // current page
    if (page > 1) {
        params.set(filterKeys.page, `${page}`);
    }

    return params;
};
