import { SvgIcon, type SxProps } from '@mui/material';

export { default as AccessibilityNewIcon } from '@mui/icons-material/AccessibilityNew';
export { default as AccessTimeIcon } from '@mui/icons-material/AccessTime';
export { default as AccountBoxIcon } from '@mui/icons-material/AccountBox';
export { default as AccountTreeIcon } from '@mui/icons-material/AccountTree';
export { default as AssignmentIndIcon } from '@mui/icons-material/AssignmentInd';
export { default as AutoGraphIcon } from '@mui/icons-material/AutoGraph';
export { default as AutorenewIcon } from '@mui/icons-material/Autorenew';
export { default as CalendarMonthIcon } from '@mui/icons-material/CalendarMonth';
export { default as ChatIcon } from '@mui/icons-material/Chat';
export { default as CheckBoxIcon } from '@mui/icons-material/CheckBox';
export { default as ClearIcon } from '@mui/icons-material/Clear';
export { default as ContentCopyIcon } from '@mui/icons-material/ContentCopy';
export { default as DashboardIcon } from '@mui/icons-material/Dashboard';
export { default as DateRangeIcon } from '@mui/icons-material/DateRange';
export { default as DeleteIcon } from '@mui/icons-material/Delete';
export { default as DirectionsRun } from '@mui/icons-material/DirectionsRun';
export { default as DoDisturbOnIcon } from '@mui/icons-material/DoDisturbOn';
export { default as DoneIcon } from '@mui/icons-material/Done';
export { default as DownloadIcon } from '@mui/icons-material/Download';
export { default as EditIcon } from '@mui/icons-material/Edit';
export { default as EmailIcon } from '@mui/icons-material/Email';
export { default as ExpandLessIcon } from '@mui/icons-material/ExpandLess';
export { default as ExpandMoreIcon } from '@mui/icons-material/ExpandMore';
export { default as FactCheckIcon } from '@mui/icons-material/FactCheck';
export { default as FavoriteIcon } from '@mui/icons-material/Favorite';
export { default as FavoriteBorderIcon } from '@mui/icons-material/FavoriteBorder';
export { default as FeedIcon } from '@mui/icons-material/Feed';
export { default as FiberNewIcon } from '@mui/icons-material/FiberNew';
export { default as FilterNoneIcon } from '@mui/icons-material/FilterNone';
export { default as GroupsIcon } from '@mui/icons-material/Groups';
export { default as HelpOutlineIcon } from '@mui/icons-material/HelpOutline';
export { default as HistoryIcon } from '@mui/icons-material/History';
export { default as HowToRegIcon } from '@mui/icons-material/HowToReg';
export { default as ImageIcon } from '@mui/icons-material/Image';
export { default as KeyboardIcon } from '@mui/icons-material/Keyboard';
export { default as KeyboardDoubleArrowRightIcon } from '@mui/icons-material/KeyboardDoubleArrowRight';
export { default as LinearScaleIcon } from '@mui/icons-material/LinearScale';
export { default as LinkIcon } from '@mui/icons-material/Link';
export { default as ListAltIcon } from '@mui/icons-material/ListAlt';
export { default as BeforeIcon } from '@mui/icons-material/NavigateBefore';
export { default as NextIcon } from '@mui/icons-material/NavigateNext';
export { default as NotificationsIcon } from '@mui/icons-material/Notifications';
export { default as PaletteIcon } from '@mui/icons-material/Palette';
export { default as PeopleIcon } from '@mui/icons-material/People';
export { default as PersonIcon } from '@mui/icons-material/Person';
export { default as PersonAddIcon } from '@mui/icons-material/PersonAdd';
export { default as PersonAddDisabledIcon } from '@mui/icons-material/PersonAddDisabled';
export { default as PersonOutlineIcon } from '@mui/icons-material/PersonOutline';
export { default as PhoneIcon } from '@mui/icons-material/Phone';
export { default as PollIcon } from '@mui/icons-material/Poll';
export { default as PublishIcon } from '@mui/icons-material/Publish';
export { default as PushPinIcon } from '@mui/icons-material/PushPin';
export { default as PushPinOutlinedIcon } from '@mui/icons-material/PushPinOutlined';
export { default as ReplyIcon } from '@mui/icons-material/Reply';
export { default as ScheduleIcon } from '@mui/icons-material/Schedule';
export { default as SearchIcon } from '@mui/icons-material/Search';
export { default as SearchOffIcon } from '@mui/icons-material/SearchOff';
export { default as SecurityIcon } from '@mui/icons-material/Security';
export { default as SettingsIcon } from '@mui/icons-material/Settings';
export { default as SpeakerNotesIcon } from '@mui/icons-material/SpeakerNotes';
export { default as SportsTennisIcon } from '@mui/icons-material/SportsTennis';
export { default as SubjectIcon } from '@mui/icons-material/Subject';
export { default as TerminalIcon } from '@mui/icons-material/Terminal';
export { default as TodayIcon } from '@mui/icons-material/Today';
export { default as ZoomOutMapIcon } from '@mui/icons-material/ZoomOutMap';

// from https://github.com/mui-org/material-ui/blob/v4.x/docs/src/pages/components/tree-view/CustomizedTreeView.js
export const MinusSquareIcon: React.FC = () => (
    <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }}>
        <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 11.023h-11.826q-.375 0-.669.281t-.294.682v0q0 .401.294 .682t.669.281h11.826q.375 0 .669-.281t.294-.682v0q0-.401-.294-.682t-.669-.281z" />
    </SvgIcon>
);

export const PlusSquareIcon: React.FC = () => (
    <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }}>
        <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 12.977h-4.923v4.896q0 .401-.281.682t-.682.281v0q-.375 0-.669-.281t-.294-.682v-4.896h-4.923q-.401 0-.682-.294t-.281-.669v0q0-.401.281-.682t.682-.281h4.923v-4.896q0-.401.294-.682t.669-.281v0q.401 0 .682.281t.281.682v4.896h4.923q.401 0 .682.281t.281.682v0q0 .375-.281.669t-.682.294z" />
    </SvgIcon>
);

export const CaruupeIcon: React.FC<{ sx: SxProps }> = ({ sx }) => (
    <SvgIcon fontSize="inherit" sx={sx} style={{ width: 20, height: 20 }}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 250 250">
            <path
                fill="currentColor"
                d="M42.23,125.5V125c0-49.76,37.51-90.52,91.27-90.52c33,0,52.76,11,69.01,27.01l-24.5,28.25 c-13.5-12.25-27.26-19.75-44.76-19.75c-29.5,0-50.76,24.51-50.76,54.51v0.5c0,30.01,20.76,55.01,50.76,55.01 c20,0,32.26-8,46.01-20.5l24.51,24.75c-18.01,19.25-38.01,31.26-71.77,31.26C80.49,215.52,42.23,175.76,42.23,125.5z"
            />
        </svg>
    </SvgIcon>
);
