import {
    Alert,
    Box,
    MenuItem,
    TableCell,
    tableCellClasses,
    tableHeadClasses,
    TableRow,
    TextField,
    Typography,
} from '@mui/material';
import type { Team, TeamId } from '@spec/Organization';
import type { Talent } from '@spec/Talent';
import type { DailyBackupResponse } from '@spec/humanCapital';
import dayjs from 'dayjs';
import { useState } from 'react';
import { ApplicationError, AuthError } from '../../Errors';
import { getTeamUrl } from '../../Routes';
import { findChildTeams, generateBreadcrumbs, isAvailableTeam } from '../../domains/Organization';
import { isEnrolledTalent, mergeTalentsAndPrivacies } from '../../domains/Talent';
import {
    calcAverageAge,
    calcAverageTenure,
    calcFemaleRatio,
    isManager,
    isPermanentEmployee,
    isSkilledTalent,
} from '../../domains/humanCapital';
import { useForm, useFormText } from '../../lib/Form';
import { useBackupDates, useDailyBackup } from '../../queries/humanCapital';
import { NoItems } from '../NoItems';
import { RouterLink } from '../RouterLink';
import { SortableTableWithCopyButton } from '../SortableTable';
import { UnitText } from '../UnitText';
import { ErrorContent, WaitQuery } from '../WaitLoading';
import { ColumnsHelp } from './ColumnsHelp';

interface Row {
    id: string;
    teamId: TeamId;
    teamName: string;
    averageTenure: number;
    averageAge: number;
    employeeCount: number;
    permanentCount: number;
    newcomerCount: number;
    withdrawalCount: number;
    sexUnknownCount: number;
    sexMaleCount: number;
    sexFemaleCount: number;
    femaleRatio: number;
    managerCount: number;
    femaleManagerCount: number;
    femaleManagersRatio: number;
    skilledTalentCount: number;
    skilledFemaleCount: number;
    skilledFemaleRatio: number;
}

const headers: Array<[keyof Row, string]> = [
    ['id', '組織名'],
    ['employeeCount', '従業員数'],
    ['permanentCount', '正社員数'],
    ['newcomerCount', '入社'],
    ['withdrawalCount', '退職'],
    ['averageTenure', '平均勤続年数'],
    ['averageAge', '平均年齢'],
    ['sexUnknownCount', '性別不明'],
    ['sexMaleCount', '男性'],
    ['sexFemaleCount', '女性'],
    ['femaleRatio', '女性比率'],
    ['managerCount', '管理職'],
    ['femaleManagerCount', '女性管理職'],
    ['femaleManagersRatio', '女性管理職比率'],
    ['skilledTalentCount', '高度人材'],
    ['skilledFemaleCount', '高度女性人材'],
    ['skilledFemaleRatio', '高度女性人材比率'],
];

const filterMembers = (team: Team, teams: Team[], talents: Talent[]) => {
    const teamIds = findChildTeams(team.id, teams).map((v) => v.id);
    return talents.filter((v) => teamIds.includes(v.teamId));
};

const fixNumber = (value: number) =>
    Number((isNaN(value) || !isFinite(value) ? 0 : value).toFixed(2));

const percentage = (value: number) =>
    Number((isNaN(value) || !isFinite(value) ? 0 : value).toFixed(4));

export const HumanCapital = () => {
    const maybeBackupDates = useBackupDates();
    return (
        <Box>
            <Alert severity="info">
                まだ下地づくりの段階なのでここに出てくる数字は実際のものと異なります
            </Alert>
            <Box my={2}>
                <ColumnsHelp />
            </Box>
            <WaitQuery
                query={maybeBackupDates}
                error={(e) =>
                    e instanceof AuthError ? (
                        <Alert severity="warning">
                            権限が不足しています。このページが見えている人には基本的に権限を付与する前提なのでSlackに「見えないんだけど」と投げてみてください。
                        </Alert>
                    ) : (
                        <ErrorContent />
                    )
                }
            >
                {({ data }) =>
                    data.length === 0 ? (
                        <NoItems>バックアップが存在しません</NoItems>
                    ) : (
                        <DateSelector dates={data} />
                    )
                }
            </WaitQuery>
        </Box>
    );
};

const CapitalLoader = (props: { date: Date }) => {
    const dailyBackup = useDailyBackup(props.date);
    return (
        <WaitQuery query={dailyBackup}>
            {({ data }) => <CapitalTable date={props.date} backup={data} />}
        </WaitQuery>
    );
};

const CapitalTable = (props: { date: Date; backup: DailyBackupResponse }) => {
    const currentTime = props.date;
    const { teams } = props.backup;
    const talents = mergeTalentsAndPrivacies(props.backup.talents, props.backup.privacies);
    const availableTeams = teams.filter(isAvailableTeam);
    const baseTeams = availableTeams.filter((v) => v.isBaseTeam);
    const targetMonth = dayjs(currentTime);
    const rows: Row[] = baseTeams.flatMap((team) => {
        const memberIds = new Set(filterMembers(team, teams, talents).map((v) => v.id));
        if (memberIds.size === 0) {
            return [];
        }
        const members = talents.filter((v) => memberIds.has(v.id)).filter(isEnrolledTalent);
        const permanents = members.filter(isPermanentEmployee);
        const managers = permanents.filter((v) => isManager(v, permanents, availableTeams));
        const permanentsWithLeavers = talents
            .filter((v) => memberIds.has(v.id))
            .filter(isPermanentEmployee);
        const skilledTalents = permanents.filter(isSkilledTalent);
        return [
            {
                id: generateBreadcrumbs(team.id, teams)
                    .map((v) => v.code ?? '')
                    .join('-'),
                teamId: team.id,
                teamName: team.name,
                averageTenure: calcAverageTenure(permanents, currentTime),
                averageAge: fixNumber(calcAverageAge(permanents, currentTime)),
                employeeCount: members.length,
                permanentCount: permanents.length,
                newcomerCount: permanentsWithLeavers.filter((v) =>
                    dayjs(v.joinedAt).isSame(targetMonth, 'month')
                ).length,
                withdrawalCount: permanentsWithLeavers.filter((v) =>
                    dayjs(v.employment.leavedAt).add(1, 'day').isSame(targetMonth, 'month')
                ).length,
                sexUnknownCount: permanents.filter((v) => v.sex === 0).length,
                sexMaleCount: permanents.filter((v) => v.sex === 1).length,
                sexFemaleCount: permanents.filter((v) => v.sex === 2).length,
                femaleRatio: percentage(calcFemaleRatio(permanents)),
                managerCount: managers.length,
                femaleManagerCount: managers.filter((v) => v.sex === 2).length,
                femaleManagersRatio: percentage(calcFemaleRatio(managers)),
                skilledTalentCount: skilledTalents.length,
                skilledFemaleCount: skilledTalents.filter((v) => v.sex === 2).length,
                skilledFemaleRatio: percentage(calcFemaleRatio(skilledTalents)),
            },
        ];
    });
    return (
        <Box>
            <Typography mt={2}>{dayjs(currentTime).format('YYYY年M月D日現在')}</Typography>
            <Box my={2}>
                <SortableTableWithCopyButton
                    stickyHeader
                    headers={headers}
                    defaultSortKey="id"
                    defaultSortDirection="asc"
                    secondarySortKey="id"
                    rows={rows}
                    serializeRow={(row) => [
                        row.teamName,
                        row.employeeCount,
                        row.permanentCount,
                        row.newcomerCount,
                        row.withdrawalCount,
                        `${Math.floor(row.averageTenure / 12)}年${(row.averageTenure % 12).toFixed(1)}ヶ月`,
                        row.averageAge,
                        row.sexUnknownCount,
                        row.sexMaleCount,
                        row.sexFemaleCount,
                        row.femaleRatio,
                        row.managerCount,
                        row.femaleManagerCount,
                        row.femaleManagersRatio,
                        row.skilledTalentCount,
                        row.skilledFemaleCount,
                        row.skilledFemaleRatio,
                    ]}
                    sx={(theme) => ({
                        [`& .${tableHeadClasses.root} .${tableCellClasses.root}`]: {
                            paddingRight: 0,
                            backgroundColor: theme.palette.background.paper,
                            borderTop: `1px solid ${theme.palette.divider}`,
                        },
                        [`& .${tableCellClasses.root}`]: {
                            padding: '6px',
                            borderLeft: `1px solid ${theme.palette.divider}`,
                        },
                        [`& .${tableCellClasses.root}:last-child`]: {
                            borderRight: `1px solid ${theme.palette.divider}`,
                        },
                    })}
                >
                    {(row) => (
                        <TableRow hover>
                            <TableCell>
                                <RouterLink to={getTeamUrl(row.teamId)}>{row.teamName}</RouterLink>
                            </TableCell>
                            <HeadcountCell value={row.employeeCount} />
                            <HeadcountCell value={row.permanentCount} />
                            <HeadcountCell value={row.newcomerCount} />
                            <HeadcountCell value={row.withdrawalCount} />
                            <TableCell align="right">
                                {Math.floor(row.averageTenure / 12)}
                                <UnitText text="年 " />
                                <Box
                                    sx={{
                                        display: 'inline-block',
                                        width: '2.75rem',
                                        textAlign: 'right',
                                    }}
                                >
                                    {(row.averageTenure % 12).toFixed(1)}
                                    <UnitText text="ヶ月" />
                                </Box>
                            </TableCell>
                            <TableCell align="right">
                                {row.averageAge.toFixed(2)}
                                <UnitText text="歳" />
                            </TableCell>
                            <HeadcountCell value={row.sexUnknownCount} />
                            <HeadcountCell value={row.sexMaleCount} />
                            <HeadcountCell value={row.sexFemaleCount} />
                            <PercentCell value={row.femaleRatio} />
                            <HeadcountCell value={row.managerCount} />
                            <HeadcountCell value={row.femaleManagerCount} />
                            <PercentCell value={row.femaleManagersRatio} />
                            <HeadcountCell value={row.skilledTalentCount} />
                            <HeadcountCell value={row.skilledFemaleCount} />
                            <PercentCell value={row.skilledFemaleRatio} />
                        </TableRow>
                    )}
                </SortableTableWithCopyButton>
            </Box>
        </Box>
    );
};

const HeadcountCell = (props: { value: number }) => {
    return (
        <TableCell align="right">
            {props.value}
            <UnitText text="人" />
        </TableCell>
    );
};

const PercentCell: React.FC<{ value: number }> = (props) => (
    <TableCell align="right">
        {(props.value * 100).toFixed(1)}
        <UnitText text="%" />
    </TableCell>
);

const DateSelector = (props: { dates: Date[] }) => {
    const dates = props.dates
        .map(dayjs)
        .toSorted((a, b) => b.unix() - a.unix())
        .filter(
            (date, index, dates) =>
                dates.findIndex((v) => v.format('YYYY-MM') === date.format('YYYY-MM')) === index
        );
    const [date, setDate] = useState(() => {
        if (dates.length === 0) {
            throw new ApplicationError('Backup dates are empty');
        }
        return dates[0];
    });
    const form = useForm({
        date: useFormText(date.format('YYYYMMDD')),
    });
    return (
        <Box>
            <TextField
                select
                value={form.date.value}
                onChange={(e) => {
                    form.date.setValue(e.target.value);
                    setDate(dayjs(e.target.value));
                }}
            >
                {dates.map((v) => (
                    <MenuItem key={v.format('YYYYMMDD')} value={v.format('YYYYMMDD')}>
                        {v.format('YYYY年M月D日')}
                    </MenuItem>
                ))}
            </TextField>
            <CapitalLoader date={date.toDate()} />
        </Box>
    );
};
