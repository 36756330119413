import { Typography } from '@mui/material';
import { pink } from '@mui/material/colors';
import type { NotesArticleSummary } from '@spec/Notes';
import { FlexBox } from '../FlexBox';
import { FavoriteBorderIcon, FavoriteIcon } from '../Icons';

export const LikesCounter: React.FC<{ article: NotesArticleSummary }> = (props) => {
    const liked = props.article.isLiked;
    const Icon = liked ? FavoriteIcon : FavoriteBorderIcon;
    const color = liked ? pink['A200'] : 'inherit';
    return (
        <FlexBox flexWrap="nowrap" gap={0.5} sx={{ minWidth: '32px' }}>
            <Icon fontSize="small" sx={{ color }} />
            <Typography sx={{ color }}>{props.article.likes}</Typography>
        </FlexBox>
    );
};
