import { Box, Card, CardActionArea, CardContent, Typography } from '@mui/material';
import { Team } from '@spec/Organization';
import { Talent } from '@spec/Talent';
import dayjs from 'dayjs';
import { getTalentUrl } from '../../../Routes';
import { findBaseTeam, generateBreadcrumbs } from '../../../domains/Organization';
import {
    NO_GRADE_LONG_TEXT,
    fullName,
    fullNameKana,
    gradeToString,
    isLeftTalent,
} from '../../../domains/Talent';
import { findById } from '../../../lib/ArrayUtils';
import { useMeContext } from '../../../queries/me';
import { useTeamsContext } from '../../Context';
import { FlexBox } from '../../FlexBox';
import { IconText } from '../../IconText';
import { EmailIcon } from '../../Icons';
import { RouterLink } from '../../RouterLink';
import { TalentAvatar } from '../../TalentAvatar';
import { TenantContent } from '../../TenantContent';
import { UnitText } from '../../UnitText';
import { GitHubId } from '../GitHubId';
import { SlackName } from '../SlackName';
import type { DisplayMode } from './filter';

interface Props {
    talent: Talent;
    teams: Team[];
    mode: DisplayMode;
}

export const TalentListCard = (props: Props) => {
    const { grants } = useMeContext();
    const privileged = grants.showLeavedTalent;
    const color = isLeftTalent(props.talent) && !privileged ? 'text.disabled' : 'text.primary';
    return (
        <Card>
            <CardActionArea
                component={RouterLink}
                modal
                to={getTalentUrl(props.talent.employment.employeeCode)}
                sx={{
                    userSelect: 'text',
                    ':hover': { textDecoration: 'none' },
                }}
            >
                <CardContent>
                    {props.mode === 'list' && (
                        <FlexBox flexWrap="nowrap" color={color}>
                            <Box mr={1.5}>
                                <TalentAvatar size="medium" talent={props.talent} />
                            </Box>
                            <TenantContent
                                carta={<Carta {...props} />}
                                treasure2024={<Treasure2024 {...props} />}
                            />
                        </FlexBox>
                    )}
                    {props.mode === 'gallery' && <GalleryCard {...props} />}
                </CardContent>
            </CardActionArea>
        </Card>
    );
};

const Carta = (props: Props) => {
    const team = findById(props.talent.teamId, props.teams);
    const bread = generateBreadcrumbs(team.id, props.teams);
    const joinedAt = dayjs(props.talent.joinedAt);
    const leftAt =
        props.talent.employment.leavedAt === null ? null : dayjs(props.talent.employment.leavedAt);
    const secondment = props.talent.secondment;
    return (
        <Box>
            <FlexBox columnGap={1}>
                <>
                    <Typography variant="body2">{fullName(props.talent)}</Typography>
                    <Typography variant="body2">{fullNameKana(props.talent)}</Typography>
                    {props.talent.employment.email !== '' && (
                        <Box ml={1}>
                            <IconText icon={EmailIcon}>{props.talent.employment.email}</IconText>
                        </Box>
                    )}
                    {props.talent.emailAlias !== '' && (
                        <IconText icon={EmailIcon}>{props.talent.emailAlias}</IconText>
                    )}
                    <SlackName talent={props.talent} />
                    <GitHubId disableLink githubId={props.talent.githubId} />
                    <Typography ml={1} variant="body2">
                        {props.talent.employment.employeeCode}
                    </Typography>
                </>
                <Typography variant="body2">{props.talent.employment.employmentStatus}</Typography>
                <Typography variant="body2">
                    {gradeToString(props.talent.grade, NO_GRADE_LONG_TEXT)}
                </Typography>
                {props.talent.position !== '' && (
                    <Typography variant="body2">{props.talent.position}</Typography>
                )}
                <Typography variant="body2">
                    {props.talent.isNewGraduate ? '新卒' : '中途'}
                </Typography>
                <Typography variant="body2">
                    {joinedAt.format('YYYY')}
                    <UnitText text="年" />
                    {joinedAt.format('M')}
                    <UnitText text="月" />
                    {joinedAt.format('D')}
                    <UnitText text="日入社" />
                </Typography>
                {leftAt !== null && (
                    <Typography variant="body2">
                        {leftAt.format('YYYY')}
                        <UnitText text="年" />
                        {leftAt.format('M')}
                        <UnitText text="月" />
                        {leftAt.format('D')}
                        <UnitText text="日退職" />
                    </Typography>
                )}
                <Typography variant="body2">
                    {props.talent.workplace && `${props.talent.workplace}勤務`}
                </Typography>
                {secondment !== null && (
                    <>
                        <Typography variant="body2">
                            {secondment.assignmentType} : {secondment.companyName}
                        </Typography>
                        {secondment?.email !== '' && (
                            <IconText icon={EmailIcon}>{secondment?.email}</IconText>
                        )}
                    </>
                )}
            </FlexBox>
            <FlexBox>
                {bread.map((v) => (
                    <Typography key={v.id} mr={0.5} variant="body2">
                        {v.name}
                    </Typography>
                ))}
            </FlexBox>
        </Box>
    );
};

const Treasure2024 = (props: Props) => {
    const team = findById(props.talent.teamId, props.teams);
    const bread = generateBreadcrumbs(team.id, props.teams);
    return (
        <Box>
            <FlexBox columnGap={1}>
                <>
                    <Typography variant="body2">{fullName(props.talent)}</Typography>
                    <Typography variant="body2">{fullNameKana(props.talent)}</Typography>
                    <Box ml={1}>
                        <IconText icon={EmailIcon}>{props.talent.employment.email}</IconText>
                    </Box>
                    {props.talent.emailAlias !== '' && (
                        <IconText icon={EmailIcon}>{props.talent.emailAlias}</IconText>
                    )}
                    <SlackName talent={props.talent} />
                    <GitHubId disableLink githubId={props.talent.githubId} />
                    <Typography ml={1} variant="body2">
                        {props.talent.employment.employeeCode}
                    </Typography>
                </>
                <Typography variant="body2">{props.talent.position}</Typography>
                <Typography variant="body2">{dayjs(props.talent.joinedAt).year()}年</Typography>
                <Typography variant="body2">
                    {props.talent.isNewGraduate ? '新卒' : '中途入社'}
                </Typography>
                <Typography variant="body2">
                    {props.talent.workplace && `${props.talent.workplace}勤務`}
                </Typography>
            </FlexBox>
            <FlexBox>
                {bread.map((v) => (
                    <Typography key={v.id} mr={0.5} variant="body2">
                        {v.name}
                    </Typography>
                ))}
            </FlexBox>
        </Box>
    );
};

const GalleryCard = (props: Props) => {
    const { teams } = useTeamsContext();
    const t = props.talent;
    const baseTeam = findBaseTeam(t, teams) ?? findById(t.teamId, teams);
    return (
        <Box sx={{ maxWidth: '120px', boxSizing: 'border-box' }}>
            <TalentAvatar size="large" talent={t} />
            <Typography mt={1} variant="body2">
                {fullName(t)}
            </Typography>
            <Typography variant="body2">{t.nicknames || fullNameKana(t)}</Typography>
            <Typography mt={0.5} variant="body2">
                {baseTeam.name}
            </Typography>
        </Box>
    );
};
