import Looks3Icon from '@mui/icons-material/Looks3';
import LooksOneIcon from '@mui/icons-material/LooksOne';
import LooksTwoIcon from '@mui/icons-material/LooksTwo';
import { LoadingButton } from '@mui/lab';
import { Box, Card, CardContent, Typography } from '@mui/material';
import type { NotesArticleSummary } from '@spec/Notes';
import dayjs from 'dayjs';
import { useState } from 'react';
import { fullName } from '../../../domains/Talent';
import { usePopularArticles } from '../../../queries/notes';
import { ActionContainer, CardLink } from '../../ActionButtons';
import { useCurrentTimeContext } from '../../Context';
import {
    AccessTimeIcon,
    CalendarMonthIcon,
    DateRangeIcon,
    FeedIcon,
    PushPinIcon,
} from '../../Icons';
import { IconText } from '../../IconText';
import { NoItems } from '../../NoItems';
import { SubTitle } from '../../PageTitle';
import { RouterLink } from '../../RouterLink';
import { TalentAvatar } from '../../TalentAvatar';
import { WaitQuery } from '../../WaitLoading';
import { ArticleTags } from '../ArticleTags';
import { useAuthor } from '../Hooks';
import { LikesCounter } from '../LikesCounter';
import { getArticleUrl } from '../urls';

const ARTICLES_PER_PAGE = 9;
const WEEKLY_PAGE_LIMIT = 1;
const MONTHLY_PAGE_LIMIT = 10;

export const Trend = () => {
    const { currentTime } = useCurrentTimeContext();
    const now = dayjs(currentTime);
    const maybeWeeklyPopularArticles = usePopularArticles(
        now.startOf('isoWeek').toDate(),
        now.toDate(),
        ARTICLES_PER_PAGE * WEEKLY_PAGE_LIMIT
    );
    const maybeMonthlyPopularArticles = usePopularArticles(
        now.startOf('month').toDate(),
        now.toDate(),
        ARTICLES_PER_PAGE * MONTHLY_PAGE_LIMIT
    );
    return (
        <Box>
            <SubTitle mt={4} mb={2} title="今週の人気記事" icon={DateRangeIcon} />
            <WaitQuery query={maybeWeeklyPopularArticles}>
                {({ data }) => <ArticleList articles={data.articles} />}
            </WaitQuery>
            <SubTitle
                mt={4}
                mb={2}
                title={`${now.format('M')}月の人気記事`}
                icon={CalendarMonthIcon}
            />
            <WaitQuery query={maybeMonthlyPopularArticles}>
                {({ data }) => <ArticleList articles={data.articles} />}
            </WaitQuery>
        </Box>
    );
};

const ArticleList = (props: { articles: NotesArticleSummary[] }) => {
    const [page, setPage] = useState(1);
    if (props.articles.length === 0) {
        return <NoItems>いいね！された記事はまだありません</NoItems>;
    }
    const slicedArticles = props.articles.slice(0, ARTICLES_PER_PAGE * page);
    return (
        <Box>
            <Box mt={1} display="flex" flexWrap="wrap" rowGap={1} columnGap={2}>
                {slicedArticles.map((v, i) => (
                    <ArticleCard key={v.id} article={v} rank={i + 1} />
                ))}
            </Box>
            {props.articles.length > slicedArticles.length && (
                <ActionContainer mt={2}>
                    <LoadingButton
                        fullWidth
                        variant="outlined"
                        size="medium"
                        loadingPosition="start"
                        startIcon={<FeedIcon />}
                        onClick={() => setPage((current) => current + 1)}
                    >
                        もっと見る
                    </LoadingButton>
                </ActionContainer>
            )}
        </Box>
    );
};

const RankIcon = (props: { rank: number }) => {
    if (props.rank > 3) {
        return null;
    }
    return (
        <Box sx={{ position: 'absolute', top: -3, right: -3 }}>
            {props.rank === 1 && <LooksOneIcon sx={{ color: '#F8D000' }} />}
            {props.rank === 2 && <LooksTwoIcon sx={{ color: '#C0C0C0' }} />}
            {props.rank === 3 && <Looks3Icon sx={{ color: '#CD7F32' }} />}
        </Box>
    );
};

const ArticleCard: React.FC<{ article: NotesArticleSummary; rank: number }> = (props) => {
    const v = props.article;
    const author = useAuthor(v);
    const articleUrl = getArticleUrl(v.id, author.hitonowaId);
    return (
        <Card
            square={false}
            sx={(theme) => ({
                flexGrow: 1,
                // Ensure consistent sizing and prevent stretching
                flexBasis: `calc(33.3333% - ${theme.spacing(2 * 2)} / 3)`,
                maxWidth: `calc(33.3333% - ${theme.spacing(2 * 2)} / 3)`,
            })}
        >
            <CardLink to={articleUrl} sx={{ height: '100%' }}>
                <CardContent sx={{ position: 'relative' }}>
                    <RankIcon rank={props.rank} />
                    <RouterLink display="inline-block" variant="h6" to={articleUrl} modal>
                        {v.title}
                        {v.isPinned === true && <PushPinIcon fontSize="small" color="secondary" />}
                    </RouterLink>
                    <Box mt={1} display="flex" rowGap={0} columnGap={1}>
                        <TalentAvatar size="medium" talent={author} />
                        <Box flexGrow={1}>
                            <Typography variant="body2">
                                {fullName(author)}
                                {author.nicknames !== '' && `（${author.nicknames}）`}
                            </Typography>
                            <IconText icon={AccessTimeIcon}>
                                {dayjs(props.article.publishedAt).format('YYYY-MM-DD HH:mm')}
                            </IconText>
                        </Box>
                        <LikesCounter article={props.article} />
                    </Box>
                    <ArticleTags tags={v.tags} />
                </CardContent>
            </CardLink>
        </Card>
    );
};
