import { Box, Container, Divider, Typography } from '@mui/material';
import { NotesArticleSummary } from '@spec/Notes';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ArticleCard } from '.';
import { RoutingPattern } from '../../../Routes';
import { useTaggedNote } from '../../../queries/notes';
import { FlexBox } from '../../FlexBox';
import { NoItems } from '../../NoItems';
import { Pager, sliceItems } from '../../Pager';
import { WaitQuery } from '../../WaitLoading';

export const ListByTag: React.FC = () => {
    const { tag } = useParams();
    const navigate = useNavigate();

    if (tag === undefined) {
        navigate(RoutingPattern.notes);
        return null;
    }

    return (
        <Container maxWidth="md">
            <Content tag={tag} />
        </Container>
    );
};

const Content: React.FC<{ tag: string }> = (props) => {
    const { tag } = props;
    const maybeArticles = useTaggedNote(tag);
    return (
        <WaitQuery query={maybeArticles}>
            {({ data }) => (
                <>
                    <FlexBox gap={1}>
                        <Typography variant="h6">#{tag}の記事一覧</Typography>
                    </FlexBox>
                    <Box mt={0.5}>
                        <Divider />
                    </Box>
                    <Box mt={1} mx={2}>
                        <ArticleList articles={data.articles} />
                    </Box>
                </>
            )}
        </WaitQuery>
    );
};

const ITEMS_PER_PAGE = 20;

const ArticleList: React.FC<{ articles: NotesArticleSummary[] }> = (props) => {
    const [page, setPage] = useState(1);
    if (props.articles.length === 0) {
        return <NoItems mt={8}>まだ記事が書かれていません</NoItems>;
    }
    const articlesSlice = sliceItems(props.articles, page, ITEMS_PER_PAGE);
    return (
        <Box>
            <Pager
                current={page}
                setPage={setPage}
                amount={props.articles.length}
                perItems={ITEMS_PER_PAGE}
            />
            {articlesSlice.map((v) => (
                <ArticleCard key={v.id} article={v} />
            ))}
            <Pager
                current={page}
                setPage={setPage}
                amount={props.articles.length}
                perItems={ITEMS_PER_PAGE}
            />
        </Box>
    );
};
