import { LoadingButton } from '@mui/lab';
import { Box, Card, CardContent, Container, Typography } from '@mui/material';
import { NotesArticleSummary } from '@spec/Notes';
import type { DefinedInfiniteQueryObserverResult, InfiniteData } from '@tanstack/react-query';
import dayjs from 'dayjs';
import React from 'react';
import { Outlet } from 'react-router-dom';
import { ApplicationError } from '../../../Errors';
import { fullName } from '../../../domains/Talent';
import { useLikedArticles, useNotesArticles } from '../../../queries/notes';
import { ActionContainer } from '../../ActionButtons';
import { FlexBox } from '../../FlexBox';
import { IconText } from '../../IconText';
import { AccessTimeIcon, FeedIcon, PushPinIcon } from '../../Icons';
import { NoItems } from '../../NoItems';
import { RouterLink } from '../../RouterLink';
import { TalentAvatar } from '../../TalentAvatar';
import { WaitInfiniteQuery } from '../../WaitLoading';
import { HitonowaId } from '../../talent/HitonowaId';
import { ArticleTags } from '../ArticleTags';
import { useAuthor } from '../Hooks';
import { LikesCounter } from '../LikesCounter';
import { TabNav, type TabMode } from '../TabNav';
import { Trend } from '../trend';
import { getArticleUrl, getAuthorUrl } from '../urls';
import { NotesHeader } from './Header';

export const Articles = (props: { mode: TabMode }) => {
    const Content = (() => {
        switch (props.mode) {
            case 'recent':
                return RecentLoader;
            case 'liked':
                return LikedLoader;
            case 'trend':
                return Trend;
            default:
                throw new ApplicationError(`unknown tab mode ${props.mode}`);
        }
    })();
    return (
        <Box>
            <Box mb={2}>
                <NotesHeader />
            </Box>
            <Container maxWidth="md">
                <TabNav mode={props.mode} />
                <Box mt={4}>
                    <Content />
                </Box>
            </Container>
            <Outlet />
        </Box>
    );
};

const RecentLoader = () => {
    const maybeArticles = useNotesArticles();
    return (
        <WaitInfiniteQuery query={maybeArticles}>
            {(result) => <ArticleList {...result} />}
        </WaitInfiniteQuery>
    );
};

const LikedLoader = () => {
    const maybeArticles = useLikedArticles();
    return (
        <WaitInfiniteQuery query={maybeArticles}>
            {(result) => <ArticleList {...result} />}
        </WaitInfiniteQuery>
    );
};

const ArticleList = <T extends { articles: NotesArticleSummary[] }>(
    // succeeded result
    props: DefinedInfiniteQueryObserverResult<InfiniteData<T>>
) => {
    const articles = props.data.pages.flatMap((page) => page.articles);
    if (articles.length === 0) {
        return <NoItems>まだ記事をいいね！していません</NoItems>;
    }
    return (
        <Box>
            {articles.map((v) => (
                <ArticleCard key={v.id} article={v} />
            ))}
            <ActionContainer>
                <LoadingButton
                    fullWidth
                    variant="outlined"
                    size="medium"
                    loading={props.isFetching}
                    loadingPosition="start"
                    startIcon={<FeedIcon />}
                    onClick={() => props.fetchNextPage()}
                    disabled={!props.hasNextPage}
                >
                    もっと見る
                </LoadingButton>
            </ActionContainer>
        </Box>
    );
};

export const ArticleCard: React.FC<{ article: NotesArticleSummary }> = (props) => {
    const v = props.article;
    const author = useAuthor(v);
    const articleUrl = getArticleUrl(v.id, author.hitonowaId);
    const authorUrl = getAuthorUrl(author.hitonowaId);
    return (
        <Card square={false}>
            <CardContent sx={{ pb: 0 }}>
                <FlexBox flexWrap="nowrap" gap={1}>
                    <RouterLink to={articleUrl}>
                        <TalentAvatar size="medium" talent={author} />
                    </RouterLink>
                    <Box flexGrow={1}>
                        <FlexBox gap={0.5}>
                            <RouterLink variant="h6" to={articleUrl} modal>
                                {v.title}
                            </RouterLink>
                            {v.isPinned === true && (
                                <PushPinIcon fontSize="small" color="secondary" />
                            )}
                        </FlexBox>
                        <FlexBox gap={1} my={-0.5}>
                            <Typography variant="body2">
                                {fullName(author)}
                                {author.nicknames !== '' && `（${author.nicknames}）`}
                            </Typography>
                            <RouterLink variant="body2" to={authorUrl}>
                                <HitonowaId hitonowaId={author.hitonowaId} />
                            </RouterLink>
                            <IconText icon={AccessTimeIcon}>
                                {dayjs(props.article.publishedAt).format('YYYY-MM-DD HH:mm')}
                            </IconText>
                            <ArticleTags tags={v.tags} />
                        </FlexBox>
                    </Box>
                    <LikesCounter article={props.article} />
                </FlexBox>
            </CardContent>
        </Card>
    );
};
